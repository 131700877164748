<template>
  <div>
    <span><i class="bi bi-edit mr" title="Edit" @click="$emit('edit', id)" /></span>
    <span class="right"><i title="Like" class="bi" :class="liked ? 'bi-star-fill' : 'bi-star'" @click="$emit('likeToggle', id)" /></span>
    <span @click="copy" class="word">{{ word }}</span>
  </div>
</template>

<script>
import useClipboard from "vue-clipboard3";
const { toClipboard } = useClipboard();

export default {
  props: {
    id: Number,
    word: String,
    liked: Boolean,
  },
  methods: {
    copy(e) {
      toClipboard(this.word);
      e.target.classList.add("copied");
      setTimeout(function () {
        e.target.classList.remove("copied");
      }, 1000);
    },
  },
};
</script>

<style scoped>
div {
  background-color: #eceff1;
  color: #37474f;
  padding: 0.5rem;
  margin-bottom: 1px;
  word-break: normal;
  overflow-wrap: anywhere;
}

.word {
  cursor: pointer;
  position: relative;
  display: inline-block;
}

.copied::after {
  background-color: #fb8c00;
  color: #fafafa;
  padding: 10px 15px;
  width: max-content;
  position: absolute;
  box-shadow: antiquewhite;
  z-index: 998;
  content: "Copied";
  top: 0;
  left: 50%;
  transform: translate(-50%, calc(-100% - 10px));
}

.copied::before {
  background-color: #fb8c00;
  content: " ";
  position: absolute;
  width: 15px;
  height: 15px;
  z-index: 999;
  top: 0;
  left: 50%;
  transform: translate(-50%, calc(-100% - 5px)) rotate(45deg);
}
</style>
