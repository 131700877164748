<template>
  <h1 class="center">{{ title }}</h1>
  <div style="max-width: 600px; margin: auto">
    <p class="center">Fake words that sound real.</p>
    <p>Fake words or pseudo words are made up words that sound and look real but don't exist and don't have meaning. They are intended to be pronounceable.</p>
  </div>
  <hr />
  <div class="center">
    <div style="white-space: nowrap">
      <div v-if="words.length > 0">
        <label>
          <input id="input" class="mb mt" style="width: 44%; min-width: 200px" type="text" spellcheck="false" v-model="this.words[this.words.length - 1].word" />
        </label>
        <button class="mb mt button-star" @click="likeToggle(lastWord().id)"><i title="Like" class="bi bi-main" :class="lastWord().liked ? 'bi-star-fill' : 'bi-star'" /></button>
      </div>
      <div v-else>
        <input id="input" class="mb mt" style="width: 44%; min-width: 200px" type="text" spellcheck="false" />
        <button class="mb mt button-star"><i title="Like" class="bi bi-main bi-star" /></button>
      </div>
    </div>
  </div>

  <div class="center">
    <button class="mr-half mb-half" @click="getWord"><i class="bi bi-next mr-half" />Next</button>
    <button class="mr-half mb-half" @click="mutateWord"><i class="bi bi-mutate mr-half" />Mutate</button>
    <button class="mb-half" @click="shuffleWord"><i class="bi bi-shuffle mr-half" />Shuffle</button>
  </div>

  <div class="oldwords">
    <div style="flex: 50%; margin-right: 0.25rem">
      <div v-if="words.length > 1">
        <Item v-for="word in words.slice().reverse().slice(1)" :key="word.id" :id="word.id" :word="word.word" :liked="word.liked" @likeToggle="likeToggle" @edit="edit" />
      </div>
    </div>

    <div style="flex: 50%; margin-left: 0.25rem">
      <div v-for="word in getLikedSorted()" :key="word.id">
        <Item v-if="word.liked" :id="word.id" :word="word.word" :liked="word.liked" @likeToggle="likeToggle" @edit="edit" />
      </div>
    </div>
  </div>
</template>

<script>
import Item from "./components/Item.vue";

export default {
  data: function () {
    return {
      title: "Fake Word Generator",
      words: [],
      // address: "http://127.0.0.1:8004",
      address: "https://fakeword.org",
    };
  },
  components: {
    Item,
  },
  methods: {
    isVisible(el) {
      var rect = el.getBoundingClientRect();
      var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
      return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
    },
    edit(id) {
      var word = this.words[id - 1].word;
      if (word === this.lastWord().word) return;
      this.words.push({
        id: this.words.length + 1,
        word: word,
        liked: false,
        liked_when: 0,
      });
      const el = document.getElementById("input");
      console.log(this.isVisible(el));
      if (el && !this.isVisible(el)) el.scrollIntoView({ behavior: "smooth", block: "start" });
    },
    likeToggle(id) {
      var word = this.words[id - 1];
      if (!word.liked) {
        word.liked_when = Date.now();
        word.liked = true;
        this.axios.post(`${this.address}/api/like`, { word: word.word });
      } else {
        word.liked_when = 0;
        word.liked = false;
      }
    },
    lastWord() {
      return this.words[this.words.length - 1];
    },
    getWord() {
      this.axios.get(`${this.address}/api/get`).then((response) => {
        this.words.push({
          id: this.words.length + 1,
          word: response.data,
          liked: false,
          liked_when: 0,
        });
      });
    },
    mutateWord() {
      this.axios.post(`${this.address}/api/mutate`, { word: this.lastWord().word }).then((response) => {
        this.words.push({
          id: this.words.length + 1,
          word: response.data,
          liked: false,
        });
      });
    },
    shuffleWord() {
      this.axios.post(`${this.address}/api/shuffle`, { word: this.lastWord().word }).then((response) => {
        this.words.push({
          id: this.words.length + 1,
          word: response.data,
          liked: false,
        });
      });
    },
    getLikedSorted() {
      return this.words.filter((x) => x.liked).sort((a, b) => parseFloat(b.liked_when) - parseFloat(a.liked_when));
    },
  },
  created: function () {
    this.getWord();
  },
};
</script>

<style>
body {
  background-color: #37474f;
  color: #fff3e0;
  font-family: "Noto Sans", system-ui, -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 19px;
  max-width: 960px;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-right: auto;
  margin-left: auto;
}

h1,
hr {
  color: #ff8a65;
  border-color: #ff8a65;
}

button {
  background-color: #546e7a;
  color: #fff;
  font-size: 1.2rem;
  outline: 0;
  border: 0;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

button:hover {
  background-color: #607d8b;
  cursor: pointer;
}

input,
.button-star {
  font-size: 1.5rem;
  outline: 0;
  border: 3px #f06292 solid;
  border: 3px #ff8a65 solid;
  background-color: #fafafa;
  color: #455a64;
  user-select: all;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
}

input {
  font-family: "Noto Sans", system-ui, -apple-system, BlinkMacSystemFont, sans-serif;
  border-right: 0;
}

input:focus {
  background-color: #fff;
  color: #212121;
}

.button-star {
  vertical-align: top;
  border-left: 0;
  user-select: none;
}

.button-star:hover {
  background-color: #fafafa;
}

.mr {
  margin-right: 1rem;
}

.mr-half {
  margin-right: 0.5rem;
}

.mt {
  margin-top: 1rem;
}

.mt-half {
  margin-top: 0.5rem;
}

.mb {
  margin-bottom: 1rem;
}

.mb-half {
  margin-bottom: 0.5rem;
}

.right {
  float: right;
}

.center {
  text-align: center;
}

.oldwords {
  margin: 1rem 0;
  display: flex;
}

.bi {
  user-select: none;
  vertical-align: middle;
}

.bi:hover {
  cursor: pointer;
}

.bi-main {
  vertical-align: baseline;
}

.bi-star {
  content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="%23FF5722"> <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"/> </svg>');
}
.bi-star-fill {
  content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="%23FF5722"> <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/> </svg>');
}
.bi-edit {
  content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="%23FF5722"> <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/> <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/> </svg>');
}
.bi-mutate {
  content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="%23FF8A65"> <path d="M8 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/> <path d="M13.997 5.17a5 5 0 0 0-8.101-4.09A5 5 0 0 0 1.28 9.342a5 5 0 0 0 8.336 5.109 3.5 3.5 0 0 0 5.201-4.065 3.001 3.001 0 0 0-.822-5.216zm-1-.034a1 1 0 0 0 .668.977 2.001 2.001 0 0 1 .547 3.478 1 1 0 0 0-.341 1.113 2.5 2.5 0 0 1-3.715 2.905 1 1 0 0 0-1.262.152 4 4 0 0 1-6.67-4.087 1 1 0 0 0-.2-1 4 4 0 0 1 3.693-6.61 1 1 0 0 0 .8-.2 4 4 0 0 1 6.48 3.273z"/> </svg>');
}
.bi-next {
  content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="%23FF8A65"> <path d="M 2.285156 4 L 2.285156 5.714844 L 4.570312 5.714844 L 4.570312 4.570312 L 11.425781 4.570312 L 11.425781 6.855469 L 10.277344 6.855469 L 11.425781 8 L 12.570312 9.144531 L 14.859375 6.855469 L 13.710938 6.855469 L 13.710938 2.285156 L 2.285156 2.285156 Z M 2.285156 4 "/> <path d="M 2.28125 8 L 1.136719 9.140625 L 2.285156 9.140625 L 2.285156 13.710938 L 13.710938 13.710938 L 13.710938 10.285156 L 11.425781 10.285156 L 11.425781 11.425781 L 4.570312 11.425781 L 4.570312 9.140625 L 5.71875 9.140625 L 4.574219 8 C 3.945312 7.371094 3.429688 6.855469 3.429688 6.855469 C 3.425781 6.855469 2.910156 7.371094 2.28125 8 Z M 2.28125 8 "/> </svg>');
}
.bi-shuffle {
  content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="%23FF8A65"> <path d="M5 1v8H1V1h4zM1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1H1zm13 2v5H9V2h5zM9 1a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9zM5 13v2H3v-2h2zm-2-1a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1H3zm12-1v2H9v-2h6zm-6-1a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1H9z"/> </svg>');
}
</style>
